<template>
    <div>
        <div id="publicToggleLightMode" class="dark:hidden bg-grey-300 flex justify-center items-center cursor-pointer w-10 h-10 xl:w-12 xl:h-12 2xl:w-14 2xl:h-14 rounded-full transition" @click="toggleDarkMode">
            <img src="/img/icon-lightmode.svg" class="w-5 lg:w-6 2xl:w-7"/>
        </div>

        <div id="publicToggleDarkMode" class="hidden dark:flex bg-purple-800 justify-center items-center cursor-pointer w-10 h-10 xl:w-12 xl:h-12 2xl:w-14 2xl:h-14 rounded-full transition" @click="toggleDarkMode">
            <img src="/img/icon-darkmode.svg" class="w-5 lg:w-6 2xl:w-7"/>
        </div>
    </div>
</template>

<script setup>

/**
 * This can't rely on the nuxt lifecycle else darkmode flickers
 */
const toggleDarkMode = function(){
    if(localStorage.getItem('darkMode') === 'true' || !localStorage.getItem('darkMode')){
        localStorage.setItem('darkMode', 'false');
        document.documentElement.classList.remove('dark');
    }else{
        localStorage.setItem('darkMode', 'true');
        document.documentElement.classList.add('dark');
    }
}
</script>